<template>
    <LiefengContent :isBack="true" @backClick="clckBack">
        <template v-slot:title>
            报名采集记录
            <span class="eye" @click.stop="changeEye">
                <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
            </span>
        </template>
        <template v-slot:toolsbarRight>
            <Form :model="searchForm" :label-colon="true" :inline="true" class="searchForm">
                <FormItem>
                    <Input v-model="searchForm.userName" placeholder="通过姓名查询" style="width: 150px"></Input>
                </FormItem>
                <FormItem>
                    <DatePicker transfer placeholder="通过日期查询" type="date" v-model="searchForm.operDate" style="width: 150px"></DatePicker>
                </FormItem>
                <FormItem>
                    <Input v-model="searchForm.mobile" placeholder="通过手机号查询" style="width: 150px"></Input>
                </FormItem>
                <FormItem>
                    <Select transfer v-model="searchForm.status" style="width: 150px" placeholder="通过报名状态查询">
                        <Option value>全部</Option>
                        <Option value="0">报名成功</Option>
                        <Option value="1">已签到</Option>
                        <Option value="2">已签退</Option>
                        <Option value="3">报名取消</Option>
                        <Option value="4">已记录</Option>
                        <Option value="5">已失效</Option>
                        <Option value="6">已完成</Option>
                        <Option value="7">无需签到</Option>
                    </Select>
                </FormItem>
                <RadioGroup v-model="searchForm.dateType" type="button" button-style="solid">
                    <Radio label="WEEK">本周</Radio>
                    <Radio label="MONTH">本月</Radio>
                </RadioGroup>
                <Button type="primary" @click="search" icon="ios-search" style="margin: 0 4px">查询</Button>
                <Button type="primary" style="margin-right: 4px" icon="ios-paper-plane" @click="openSendmsg">发社区通知</Button>
                <Button type="primary" style="margin-right: 4px" @click="exportExcel" icon="ios-download-outline">导出</Button>
                <Button type="primary" style="margin: 0 4px" @click="importExcel" icon="ios-add-circle-outline">导入</Button>
                <!-- <Button
                    type="primary"
                    style="margin: 0 4px"
                    @click="
                        $router.push(
                            `/nucleicacidoverview?dataScopeList=${$route.query.dataScopeList}&title=${$route.query.title}&formId=${$route.query.formId}&functionType=${$route.query.functionType}&menuCode=${$route.query.menuCode}&period=${$route.query.period}&whetherSign=${$route.query.whetherSign}$menuId=${$route.query.menuCode}`
                        )
                    "
                >
                    采集情况总览
                </Button> -->

                <!-- 创建沟通组 -->
                <Group ref="group" :informationId="$route.query.id" :userList="userList" style="margin:10px;"></Group>

                <!-- <Button
          type="error"
          icon="ios-arrow-back"
          @click="
            $router.push({
              path: '/activityindex',
              query: { menuId: $route.query.menuCode },
            })
          "
        >返回</Button> -->
            </Form>
        </template>
        <template v-slot:contentArea>
            <LiefengTable :talbeColumns="talbeColumns" :tableData="tableData" :loading="loading" :fixTable="true" :curPage="page" :total="total" :pagesizeOpts="[20, 30, 50, 100]" :page-size="pageSize" @hadlePageSize="hadlePageSize" @tableSelect="tableSelect"></LiefengTable>
            <LiefengModal title="社区通知" :value="sendmsgStatus" @input="sendmsgStatusFn" width="700px" height="400px">
                <template #contentarea>
                    <Form :model="sendmsgForm" :rules="sendmsgValidate" :label-width="80" ref="sendmsgForm">
                        <FormItem label="通知内容" prop="content">
                            <Input v-model.trim="sendmsgForm.content" type="textarea" :maxlength="20" show-word-limit :rows="4" placeholder="请填写通知内容，限20字" />
                        </FormItem>
                        <FormItem label="提示说明" prop="remark">
                            <Input v-model.trim="sendmsgForm.remark" :maxlength="20" show-word-limit placeholder="提示用户下一步操作，限20字" />
                        </FormItem>
                    </Form>
                </template>
                <template #toolsbar>
                    <Button
                        type="info"
                        @click="
                            cancelModelTip = true
                            sendmsgStatusFn(false)
                        "
                        style="margin-right: 10px"
                    >
                        取消
                    </Button>
                    <Button type="primary" @click="saveSendMsg" :loading="sendmsgLoading">
                        <span v-if="!sendmsgLoading">发送</span>
                        <span v-else>发送中...</span>
                    </Button>
                </template>
            </LiefengModal>

            <LiefengModal title="取消报名" :value="cancelStatus" @input="cancelStatusFn" width="400px">
                <template #contentarea>
                    <Form :label-width="100">
                        <FormItem>
                            <span slot="label" class="validate">取消原因</span>
                            <Select v-model="cancel" transfer>
                                <Option value="0">不满足报名条件</Option>
                                <Option value="1">用户自主取消</Option>
                                <Option value="2">临时有事来不了</Option>
                                <Option value="3">其他</Option>
                            </Select>
                        </FormItem>
                    </Form>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="cancelStatus = false">取消</Button>
                    <Button type="primary" @click="cancelSave">确定</Button>
                </template>
            </LiefengModal>
            <LiefengModal title="导入" :value="excelStatus" @input="exportModalFn">
                <template v-slot:contentarea>
                    <Upload
                        ref="excelUpload"
                        type="drag"
                        :action="excelAction"
                        name="file"
                        :max-size="10240"
                        :show-upload-list="true"
                        :before-upload="beforeExcelUpload"
                        :on-success="myExcel"
                        :data="uploadData"
                        :headers="uploadHeaders"
                        :default-file-list="fileList"
                        :format="['xls', 'xlsx', 'xlsb', 'xlsm', 'xlst']"
                        :on-format-error="uploadFormatError"
                        :on-exceeded-size="uploadSizeError"
                    >
                        <div style="padding: 20px 0; text-align: center; width: 100%">
                            <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                            <p>点击上传，或将文件拖拽到此处</p>
                        </div>
                    </Upload>
                    <div v-if="textStatus">当前选择的文件名称:{{ excelFile.name }}</div>
                    <div v-html="errorContent"></div>
                    <div style="text-align: center; margin: 20px 0">
                        <Button type="primary" style="margin-right: 20px" @click="dowunModal">模板下载</Button>
                        <Button type="info" @click="submitExcel">确认导入</Button>
                    </div>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/activityrecord');
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import proxy from "@/api/proxy"

import Group from "./childrens/Group.vue"

export default {
    data() {
        return {
            //脱敏
            hasSensitive: true,
            talbeColumns: [],
            tableData: [],
            loading: false,
            pageSize: 20,
            page: 1,
            total: 0,
            searchForm: {},
            menuCodeObj: {},

            // 社区通知
            sendmsgStatus: false,
            sendmsgForm: {
                content: "",
                remark: "",
            },
            sendmsgLoading: false,
            sendmsgValidate: {
                content: [{ required: true, message: "请填写通知内容", trigger: "blur" }],
                remark: [{ required: true, message: "请填写提示说明", trigger: "blur" }],
            },
            userList: [],
            openSendmsgIndex: 1, //辨别是否第一次点击发社区通知

            // 取消报名
            cancel: "0",
            cancelStatus: false,
            //excel导入弹窗
            excelStatus: false,
            //excel文件数据
            excelFile: null,
            //修改data
            titleData: {},
            uploadData: {},
            uploadHeaders: {},
            //导入文字显示标识
            textStatus: false,
            loading: false,
            excelAction: "",
            //excel导入错误信息
            errorContent: null,
            fileList: [],
        }
    },
    methods: {
        clckBack() {
            var index = parent.layer.getFrameIndex(window.name)
            if (index) {
                //先得到当前iframe层的索引
                parent.layer.close(index) //再执行关闭
            } else {
                this.$router.push({
                    path: "/activityindex",
                    query: { menuId: this.$route.query.menuCode },
                })
            }
        },
        // zhw添加
        // getMenuCode(){
        //   this.$get("/old/api/pc/menu/selectColumnByMenuCode", {
        //   menuCode: this.$route.query.menuCode
        //     }).then(res => {
        //       if (res.code == 200 && res.data) {
        //         this.menuCodeObj = res.data;
        //       } else {
        //         this.$Message.error({
        //           background: true,
        //           content: res.desc
        //         });
        //       }
        //     })
        // },

        dowunModal() {
            this.$Message.loading({
                content: "正在导出，请稍等...",
                duration: 0,
            })
            // let link = document.createElement("a");
            // link.href =
            //   window.vue.getProxy()["/datamsg"].target +
            //   `/api/pc/information/appointment/downloadImportRecordTemplate?informationId=${this.$route.query.id}`;
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);
            // this.$Message.destroy();
            // return;
            try {
                this.$get("/gateway/syinfopublish/api/pc/information/appointment/downloadImportRecordTemplate", {
                    informationId: this.$route.query.id || this.informationId,
                }).then(res => {
                    if (res.data) {
                        if (res.data.slice(0, 4) == "http" && res.data.slice(0, 5) !== "https") {
                            res.data = res.data.replace("http", "https")
                        }
                    }
                    let link = document.createElement("a")
                    link.href = res.data
                    document.body.appendChild(link)
                    link.click()
                    link.download = "模板下载"
                    document.body.removeChild(link)
                    this.$Message.destroy()
                })
            } catch {
                this.$Message.destroy()
                this.$Message.error({
                    background: true,
                    content: "导出失败，请联系管理员处理",
                })
            }
        },
        exportModalFn(status) {
            this.excelStatus = status
        },
        //导入按钮
        importExcel() {
            this.excelFile = null
            this.fileList = []
            this.textStatus = false
            this.excelStatus = true
        },

        //确认导入
        submitExcel() {
            if (this.excelFile) {
                this.$refs.excelUpload.post(this.excelFile)
            } else {
                this.$Message.error({
                    background: true,
                    content: "请先选择文件",
                })
            }
        },
        //导入excel格式错误
        uploadFormatError(file) {
            this.$Notice.warning({
                title: "文件格式不正确",
                desc: "文件" + file.name + "格式不正确，请上传xls,xlsx,xlsb,xlsm或xlst格式的文件",
            })
        },
        //导入excel文件大小超出限制
        uploadSizeError(file) {
            this.$Notice.error({
                title: "导入失败",
                desc: "文件 " + file.name + " 大小已超出限制，请控制在10M以内",
            })
        },
        //excel文件上传成功后执行
        myExcel(res) {
            if (res.code == 200) {
                this.$Message.success({
                    background: true,
                    content: "导入成功！",
                })
                this.hadlePageSize({
                    page: this.page,
                    pageSize: this.pageSize,
                })

                if (res.dataList.length != 0) {
                    this.errorContent = ""
                    res.dataList.map((item, index) => {
                        this.errorContent += `<div style="color:red">${item}</div>`
                    })
                    this.excelFile.name = ""
                    this.fileList = []
                } else {
                    this.textStatus = false
                    this.excelStatus = false
                }
            } else {
                this.fileList = []

                this.$Message.error({
                    background: true,
                    content: res.desc,
                })
            }
        },
        //上传之前
        beforeExcelUpload(file) {
            this.excelAction = window.vue.getProxy()["/old"].target + "/api/pc/information/appointment/importRecord"
            this.uploadData.orgCode = parent.vue.loginInfo.userinfo.orgCode
            this.uploadData.custGlobalId = parent.vue.loginInfo.userinfo.custGlobalId
            this.uploadData.informationId = this.$route.query.id
            this.uploadHeaders = {
                openId: window.sessionStorage.getItem("openId"),
                Authorization: window.sessionStorage.getItem("accessToken"),
            }
            this.excelFile = file
            this.textStatus = true
            return false
        },
        // zhw结束
        // 发社区通知
        openSendmsg() {
            if (this.tableData.length == 0) {
                this.$Modal.error({
                    title: "温馨提示",
                    content: "暂无可接收通知的人员",
                })
            } else if (this.openSendmsgIndex == 1) {
                this.$Modal.info({
                    title: "温馨提示",
                    onOk: () => {
                        this.openSendmsgIndex = 2
                        this.sendmsgStatus = true
                    },
                    content: "默认给所有填写人发送社区通知，若只需给指定填写人发送，请从表格中勾选数据或输入查询条件后再发！",
                })
            } else {
                this.sendmsgStatus = true
            }
        },
        saveSendMsg() {
            this.$refs.sendmsgForm.validate(status => {
                if (status) {
                    this.sendmsgLoading = true
                    this.$post(
                        "/gateway/syinfopublish/api/pc/information/appointment/pushMessageCustomize",
                        {
                            functionType: this.$route.query.functionType,
                            content: this.sendmsgForm.content,
                            remark: this.sendmsgForm.remark,
                            userList: (() => {
                                let arr = this.userList.map(item => {
                                    return {
                                        custGlobalId: item.custGlobalId,
                                        orgCode: item.orgCode,
                                    }
                                })
                                return arr.length > 0 ? arr : null
                            })(),
                            informationId: this.$route.query.id,
                            oemCode: parent.vue.oemInfo.oemCode,
                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            ...this.searchForm,
                        },
                        { "Content-Type": "application/json" }
                    )
                        .then(res => {
                            if (res.code == 200) {
                                this.sendmsgLoading = false
                                this.$Message.success({
                                    background: true,
                                    content: "发送成功",
                                })
                                this.cancelModelTip = false
                                this.sendmsgStatus = false
                                this.userList = []
                                this.hadlePageSize({
                                    page: this.page,
                                    pageSize: this.pageSize,
                                })
                            } else {
                                this.sendmsgLoading = false
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            this.sendmsgLoading = false
                            this.$Message.error({
                                background: true,
                                content: "发送失败",
                            })
                        })
                }
            })
        },
        tableSelect(arr) {
            let userList = []

            this.userList.map(item => {
                if (item.page != this.page) {
                    userList.push(item)
                }
            })
            this.userList = userList
            arr.map(item => {
                let custGlobalId = ""
                if (item.custGlobalId) {
                    this.userList.push({
                        custGlobalId: item.custGlobalId,
                        orgCode: item.orgCode,
                        id: item.id,
                        page: this.page,
                    })
                } else {
                    this.userList.push({
                        custGlobalId: item.agentCustGlobalId,
                        orgCode: item.orgCode,
                        id: item.id,
                        page: this.page,
                    })
                }
            })
            this.userList = this.$core.onlyList(this.userList, "custGlobalId", "id")
        },
        sendmsgStatusFn(status) {
            if (!status && this.cancelModelTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
                    onOk: () => {
                        this.sendmsgStatus = status
                    },
                })
            } else {
                this.sendmsgStatus = status
                this.$refs.sendmsgForm.resetFields()
            }
        },

        // 导出
        exportExcel() {
            const d = this.$Message.loading({
                content: "正在导出，请稍等...",
                duration: 0,
            })
            this.$.ajax({
                url:
                    proxy["/gateway"].target +
                    // "/api/pc/information/appointment/exportAppointmentUserPage",
                    "/syinfopublish/api/pc/information/appointment/exportAppointmentUserPageV2",
                type: "GET",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: window.sessionStorage.getItem("accessToken"),
                },
                data: {
                    informationId: this.$route.query.id,
                    ...this.searchForm,
                    operDate: this.searchForm.operDate ? this.$core.formatDate(new Date(this.searchForm.operDate), "yyyy-MM-dd") : "",
                },
                success: res => {
                    if (res.code != 200) {
                        return this.$Message.error(res.desc || res.msg || "系统繁忙，请稍后再试！")
                    }
                    // 创建一个 a 标签用于下载
                    const e = document.createElement("a")
                    e.download = "数据导出_" + new Date().getTime()
                    e.href = res.data
                    document.body.appendChild(e)
                    e.click()
                    document.body.removeChild(e)
                },
                error: err => {
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        content: "导出失败，请联系管理员处理",
                    })
                },
                complete: d,
            })
        },
        // 取消
        cancelSave() {
            this.$post("/gateway/syinfopublish/api/pc/information/v2/changeRecordState", {
                idList: this.infoId,
                state: this.cancel,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            background: true,
                            content: "取消成功",
                        })
                        this.hadlePageSize({
                            page: this.page,
                            pageSize: this.pageSize,
                        })
                        this.cancelStatus = false
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        content: "取消失败，请联系管理员处理",
                    })
                })
        },

        search() {
            this.hadlePageSize({
                page: 1,
                pageSize: this.pageSize,
            })
        },
        changeEye() {
            this.hasSensitive = !this.hasSensitive
            this.hadlePageSize({
                page: 1,
                pageSize: this.pageSize,
            })
        },
        hadlePageSize(obj) {
            this.loading = true
            this.$get(
                // "/datamsg/api/pc/information/appointment/selectAppointmentUserPage",
                "/gateway/syinfopublish/api/pc/information/appointment/queryAppointmentUserPageV2",
                {
                    informationId: this.$route.query.id,
                    page: obj.page,
                    pageSize: obj.pageSize,
                    ...this.searchForm,
                    staffCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                    hasSensitive: this.hasSensitive,
                    operDate: this.searchForm.operDate ? this.$core.formatDate(new Date(this.searchForm.operDate), "yyyy-MM-dd") : "",
                }
            )
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        this.page = res.currentPage
                        this.pageSize = res.pageSize
                        this.total = res.maxCount
                        res.dataList.map(item => {
                            this.userList.map(subItem => {
                                if (subItem.id == item.id) {
                                    item._checked = true
                                }
                            })
                            if (item.status !== null && item.status !== undefined) {
                                switch (item.status) {
                                    case "0":
                                        item.status = "报名成功"
                                        break
                                    case "1":
                                        item.status = "已签到"
                                        break
                                    case "2":
                                        item.status = "已签退"
                                        break
                                    case "3":
                                        item.status = "报名取消"
                                        item._disabled = true
                                        break
                                    case "4":
                                        item.status = "已记录"
                                        break
                                    case "5":
                                        item.status = "已失效"
                                        break
                                    case "6":
                                        item.status = "已完成"
                                        break
                                    case "7":
                                        item.status = "无需签到"
                                        break
                                }
                            }
                            if (item.operDate) {
                                item.operDate = this.$core.formatDate(new Date(item.operDate), "yyyy-MM-dd")
                            }
                            if (item.gmtCreate) {
                                item.gmtCreate = this.$core.formatDate(new Date(item.gmtCreate), "yyyy-MM-dd hh:mm:ss")
                            }
                            if (item.agent) {
                                item.agent = item.agent == "0" ? "否" : item.agent == "1" ? "是" : ""
                            }
                            if (item.sex) {
                                item.sex = item.sex == "1" ? "男" : item.sex == "2" ? "女" : ""
                            }
                            if (item.startSignTime) {
                                item.startSignTime = this.$core.formatDate(new Date(item.startSignTime), "yyyy-MM-dd hh:mm:ss")
                            }
                            if (item.endSignTime) {
                                item.endSignTime = this.$core.formatDate(new Date(item.endSignTime), "yyyy-MM-dd hh:mm:ss")
                            }
                        })
                        this.tableData = res.dataList
                        this.loading = false
                    } else {
                        this.loading = false
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.loading = false
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请重试",
                    })
                })
        },
    },
    async created() {
        // 获取表格表头信息
        const res = await this.$get("/datamsg/api/pc/information/v2/selectDynamicFormHeader", {
            businessType: this.menuCodeObj.businessType,
            functionType: this.menuCodeObj.functionType,
            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            orgCode: parent.vue.loginInfo.userinfo.orgCode,
            oemCode: parent.vue.oemInfo.oemCode,
            terminal: "2",
            informationId: this.informationId || this.$route.query.id, //跨权限操作，url传informationId，直接从菜单进入采集信息
        }).catch(err => {
            console.log(err)
            this.$Message.error({
                background: true,
                content: "获取数据失败，请联系管理员处理",
            })
        })

        var head = []

        if (res.code === "200") {
            head = res.dataList.map(item => {
                let paramsObj = {}
                if (item.fieldType == "image") {
                    paramsObj = {
                        render: (h, params) => {
                            if (!params.row.busiQuestionInfo[item.classField]) params.row.busiQuestionInfo[item.classField] = []
                            let answer = params.row.busiQuestionInfo[item.classField].split(",")
                            return answer.map(subItem => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            textAlign: "center",
                                            padding: "10px 0",
                                            display: "inline-block",
                                            marginRight: "10px",
                                            cursor: "pointer",
                                        },
                                    },
                                    [
                                        h(
                                            "a",
                                            {
                                                attrs: {
                                                    target: "view_window",
                                                    href: subItem,
                                                },
                                            },
                                            [
                                                h("img", {
                                                    attrs: {
                                                        src: subItem,
                                                    },
                                                    style: {
                                                        width: "40px",
                                                        height: "40px",
                                                    },
                                                }),
                                            ]
                                        ),
                                    ]
                                )
                            })
                        },
                    }
                } else if (item.fieldType == "video") {
                    paramsObj = {
                        render: (h, params) => {
                            if (!params.row.busiQuestionInfo[item.classField]) params.row.busiQuestionInfo[item.classField] = []
                            let answer = params.row.busiQuestionInfo[item.classField].split(",")
                            return answer.map(subItem => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            textAlign: "center",
                                            padding: "10px 0",
                                            display: "inline-block",
                                            marginRight: "10px",
                                            cursor: "pointer",
                                        },
                                        on: {
                                            click: () => {
                                                let page = window.open()
                                                var html = "<body style='background:black'> <div style='width:80%;margin:auto;'> <video controls width='100%' height='90%' autoplay src=" + subItem + "></video></div></body>"
                                                page.document.write(html)
                                            },
                                        },
                                    },
                                    [
                                        h("video", {
                                            attrs: {
                                                width: "100%",
                                                height: "100px",
                                                src: subItem,
                                            },
                                        }),
                                    ]
                                )
                            })
                        },
                    }
                } else if (item.fieldType == "file") {
                    paramsObj = {
                        render: (h, params) => {
                            if (!params.row.busiQuestionInfo[item.classField]) params.row.busiQuestionInfo[item.classField] = []
                            let answer = params.row.busiQuestionInfo[item.classField].split(",")
                            return answer.map(subItem => {
                                return h(
                                    "a",
                                    {
                                        attrs: {
                                            href: subItem,
                                            download: subItem.substring(subItem.lastIndexOf("/") + 9),
                                            target: "view_window",
                                        },
                                        style: {
                                            display: "block",
                                        },
                                    },
                                    subItem.substring(subItem.lastIndexOf("/") + 9)
                                )
                            })
                        },
                    }
                } else if (item.fieldType == "date") {
                    paramsObj = {
                        render: (h, params) => {
                            return h("div", params.row.busiQuestionInfo[item.classField] ? this.$core.formatDate(new Date(params.row.busiQuestionInfo[item.classField]), "yyyy-MM-dd") : "")
                        },
                    }
                } else if (item.fieldType == "cascader") {
                    paramsObj = {
                        render: (h, params) => {
                            return h("div", params.row.busiQuestionInfo?.[item.classField]?.replace(/\&[\0-\9]*/g, "") || "")
                        },
                    }
                } else {
                    paramsObj = {
                        render: (h, params) => {
                            return h("div", params.row.busiQuestionInfo?.[item.classField] || "")
                        },
                    }
                }
                return {
                    minWidth: 200,
                    ...paramsObj,
                    renderHeader: (h, params) => {
                        return h("div", {
                            domProps: {
                                innerHTML: item.pageFieldName,
                            },
                            style: {
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            },
                        })
                    },
                }
            })
        }

        console.log(head)

        console.log(this.$route.query.nameType)
        // none 不实名   true 实名  false 网上家园认证
        // 实名
        let arrNameTrue = [
            {
                type: "selection",
                width: 60,
                align: "center",
            },
            {
                title: "区",
                key: "zoneName",
                width: 150,
                align: "center",
            },
            {
                title: "街镇",
                key: "streetName",
                width: 150,
                align: "center",
            },
            {
                title: "社区",
                key: "orgName",
                width: 150,
                align: "center",
            },
            {
                title: "姓名",
                key: "userName",
                width: 110,
                align: "center",
            },
            {
                title: "手机号",
                key: "mobile",
                width: 130,
                align: "center",
            },
            {
                title: "证件号码",
                key: "idNum",
                width: 180,
                align: "center",
            },
            {
                title: "年龄",
                key: "age",
                width: 80,
                align: "center",
            },
            {
                title: "性别",
                key: "sex",
                width: 80,
                align: "center",
            },
            {
                title: "现住地址",
                key: "currentAddr",
                width: 130,
                align: "center",
            },
        ]
        //  网上家园认证
        let arrNamefalse = [
            {
                type: "selection",
                width: 60,
                align: "center",
            },
            {
                title: "区",
                key: "zoneName",
                width: 150,
                align: "center",
            },
            {
                title: "街镇",
                key: "streetName",
                width: 150,
                align: "center",
            },
            {
                title: "社区",
                key: "orgName",
                width: 150,
                align: "center",
            },
            {
                title: "姓名",
                key: "userName",
                width: 110,
                align: "center",
            },
            {
                title: "手机号",
                key: "mobile",
                width: 130,
                align: "center",
            },
            {
                title: "现住地址",
                key: "currentAddr",
                width: 130,
                align: "center",
            },
        ]

        // 不认证
        let arrNamenone = [
            {
                type: "selection",
                width: 60,
                align: "center",
            },
            {
                title: "区",
                key: "zoneName",
                width: 150,
                align: "center",
            },
            {
                title: "街镇",
                key: "streetName",
                width: 150,
                align: "center",
            },
            {
                title: "社区",
                key: "orgName",
                width: 150,
                align: "center",
            },
            {
                title: "姓名",
                key: "userName",
                minWidth: 110,
                align: "center",
            },
            {
                title: "手机号",
                key: "mobile",
                minWidth: 130,
                align: "center",
            },
        ]

        let arr2 = [
            {
                title: "报名日期",
                key: "operDate",
                width: 180,
                align: "center",
            },
            {
                title: "时间段",
                key: "cycleTime",
                width: 180,
                align: "center",
            },
        ]
        let arr3 = [
            {
                title: "报名时间",
                key: "gmtCreate",
                width: 180,
                align: "center",
            },
            {
                title: "是否代报名",
                key: "agent",
                width: 110,
                align: "center",
            },
            {
                title: "代报人",
                key: "agentName",
                width: 130,
                align: "center",
            },
            {
                title: "代报人手机号",
                key: "agentMobile",
                width: 130,
                align: "center",
            },
        ]
        let arr4 = [
            {
                title: "签到时间",
                key: "startSignTime",
                width: 180,
                align: "center",
            },
            {
                title: "签出时间",
                key: "endSignTime",
                width: 180,
                align: "center",
            },
        ]
        let arr5 = [
            {
                title: "报名状态",
                key: "status",
                width: 100,
                align: "center",
            },
            ...head,
            {
                title: "操作",
                width: 95,
                fixed: "right",
                align: "center",
                render: (h, params) => {
                    return h(
                        "Button",
                        {
                            props: {
                                type: "primary",
                                size: "small",
                                disabled: params.row.status == "报名取消" || this.$route.query.disabled ? true : false,
                            },
                            on: {
                                click: () => {
                                    this.infoId = params.row.id
                                    this.cancel = "0"
                                    this.cancelStatus = true
                                },
                            },
                        },
                        params.row.status == "报名取消" ? "已取消" : "取消" //liang
                    )
                },
            },
        ]

        if (this.$route.query.nameType == "true") {
            // 根据上级传过来的参数判断是否表格是否需要显示签到
            if (this.$route.query.whetherSign == "1") {
                //需签到
                if (this.$route.query.period == "0") {
                    //无预约时段
                    this.talbeColumns = arrNameTrue.concat(arr3, arr4, arr5)
                } else {
                    //有预约时段
                    this.talbeColumns = arrNameTrue.concat(arr2, arr3, arr4, arr5)
                }
            } else {
                // 不需签到
                if (this.$route.query.period == "0") {
                    //无预约时段
                    this.talbeColumns = arrNameTrue.concat(arr3, arr5)
                } else {
                    //有预约时段
                    this.talbeColumns = arrNameTrue.concat(arr2, arr3, arr5)
                }
            }
        } else if (this.$route.query.nameType == "false") {
            // 根据上级传过来的参数判断是否表格是否需要显示签到
            if (this.$route.query.whetherSign == "1") {
                //需签到
                if (this.$route.query.period == "0") {
                    //无预约时段
                    this.talbeColumns = arrNamefalse.concat(arr3, arr4, arr5)
                } else {
                    //有预约时段
                    this.talbeColumns = arrNamefalse.concat(arr2, arr3, arr4, arr5)
                }
            } else {
                // 不需签到
                if (this.$route.query.period == "0") {
                    //无预约时段
                    this.talbeColumns = arrNamefalse.concat(arr3, arr5)
                } else {
                    //有预约时段
                    this.talbeColumns = arrNamefalse.concat(arr2, arr3, arr5)
                }
            }
        } else {
            // 根据上级传过来的参数判断是否表格是否需要显示签到
            if (this.$route.query.whetherSign == "1") {
                //需签到
                if (this.$route.query.period == "0") {
                    //无预约时段
                    this.talbeColumns = arrNamenone.concat(arr3, arr4, arr5)
                } else {
                    //有预约时段
                    this.talbeColumns = arrNamenone.concat(arr2, arr3, arr4, arr5)
                }
            } else {
                // 不需签到
                if (this.$route.query.period == "0") {
                    //无预约时段
                    this.talbeColumns = arrNamenone.concat(arr3, arr5)
                } else {
                    //有预约时段
                    this.talbeColumns = arrNamenone.concat(arr2, arr3, arr5)
                }
            }
        }

        console.log(this.talbeColumns)

        // 获取functionType/columnCode等
        // await this.$get("/old/api/pc/menu/selectColumnByMenuCode", {
        //   menuCode: this.$route.query.menuCode
        // }).then(res => {
        //   if (res.code == 200 && res.data) {
        //     this.menuCodeObj = res.data;
        //   } else {
        //     this.$Message.error({
        //       background: true,
        //       content: res.desc
        //     });
        //   }
        // });
        this.hadlePageSize({
            page: this.page,
            pageSize: this.pageSize,
        })
    },

    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        Group,
    },
}
</script>

<style scoped lang="less">
// /deep/#toolsbarRight {
//   width: 1000%;
//   .searchForm {
//     .ivu-form-item {
//       margin-bottom: 0;
//       margin-right: 4px;
//     }
//   }
// }
/deep/.validate {
    s &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.eye {
    cursor: pointer;
    display: inline-block;
    width: 30px;
    height: 30px;
    padding-left: 10px;
    font-size: 17px;
}
</style>
